<template>
  <li class="list__item">
    <h3 class="list__item-headline" v-html="data.Lanqkey01"></h3>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
import DebugItem from '@/components/DebugItem';

export default {
  name: 'ANZHEADLINE',

  components: {
    DebugItem
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    debug() {
      return this.$store.state.app.debug;
    }
  }
};
</script>
